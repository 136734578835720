import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Stack,
  Stepper,
  Step,
  StepLabel,
  Backdrop,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useApi from "../../../../hooks/useApi";
import useContactManager from '../../../../components/getContacts';
import ContactsStepper from "../../../../components/contactsStepper"

const stepValidationSchemas = [
  yup.object().shape({
    project: yup.object().required("Requerido"),
    nomenclature: yup.string().required("Requerido"),
    prorrata: yup.string(),
    tower: yup.string(),
  }),
  yup.object().shape({
    parq1: yup.string(),
    matricula_p1: yup.string(),
    parq2: yup.string(),
    matricula_p2: yup.string(),
    util1: yup.string(),
    matricula_u1: yup.string(),
    util2: yup.string(),
    matricula_u2: yup.string(),
  }),
  yup.object().shape({
    business_type: yup.object().nullable().required("Requerido").shape({
      meaning: yup.string().required("Requerido"),
    }),

  }),
  yup.object().shape({

  }),
  yup.object().shape({
  }),
];


const steps = ["Detalles de proyecto", "Parqueo y utilidades", "Información de negocio", "Financiación", "Agentes"];


const handleKeyDown = (event) => {
  if (
    !/[0-9]/.test(event.key) &&
    event.key !== "Backspace" &&
    event.key !== "Delete" &&
    event.key !== "ArrowLeft" &&
    event.key !== "ArrowRight"
  ) {
    event.preventDefault();
  }
};
export default function Trusts({ handleClose, showSnack, updateList, rowData }) {
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState([]);
  const [TN, setTN] = useState([]);
  const [TF, setTF] = useState([]);
  const [estatus, setEstatus] = useState([]);
  const [constructors, setConstructors] = useState([]);
  const [experts, setExperts] = useState([]);
  const [lawyers, setLawyers] = useState([]);
  const [banks, setBanks] = useState([]);
  const [Notaries, setNotaries] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [contactsBank, setContactsBank] = useState([]);
  const [selectedContactBank, setSelectedContactBank] = useState(null);
  const [contactsNotaries, setContactsNotaries] = useState([]);
  const [selectedContacNotaries, setSelectedContactNotaries] = useState(null);
  const { getContactConstructor, getContactBanks, getContactNotaries } = useContactManager();

  const { control, handleSubmit, trigger, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(stepValidationSchemas[activeStep]),
    mode: "onChange",
  });

  const { loadApi } = useApi();

  const getTNList = async () => {
    setLoading(true);
    loadApi("list/all_lists", true, "get").then(({ data }) => {
      if (data.length > 0) {
        const listTF = data.find(tf => tf.name === "TF")
        const listTN = data.find(tn => tn.name === "TN")
        const listEstatus = data.find(estatus => estatus.name === "VA")
        if (listTF) {
          setTF(listTF.Lists);
        }
        if (listTN) {
          setTN(listTN.Lists);
        }
        if (listEstatus.Lists);
        setEstatus(listEstatus.Lists);
      }
    }).catch(error => showSnack(error.message, "error")).finally(() => setLoading(false));
  };


  useEffect(() => {
    getTNList();
    getData("project/all_projects", setData);
    getData("constructor/all_constructors", setConstructors);
    getData("peritos/all_peritos", setExperts);
    getData("lawyers/all_lawyers", setLawyers);
    getData("banks/all_banks", setBanks);
    getData("notaries/all_notaries", setNotaries);

    if (rowData) {
      setValue("project", rowData.Project || "");
      setValue("constructor_code", rowData.Constructor);
      setValue("nomenclature", rowData.nomenclature || "");
      setValue("prorrata", rowData.prorata || "");
      setValue("matricula", rowData.registrationNumber || "");
      setValue("tower", rowData.tower || "");
      setValue("parq1", rowData.parking1 || "");
      setValue("matricula_p1", rowData.registrationParking1 || "");
      setValue("parq2", rowData.parking2 || "");
      setValue("matricula_p2", rowData.registrationParking2 || "");
      setValue("util1", rowData.utility1 || "");
      setValue("matricula_u1", rowData.registrationUtility1 || "");
      setValue("util2", rowData.utility2 || "");
      setValue("matricula_u2", rowData.registrationUtility2 || "");
      setValue("status", rowData.status || "");
      setValue("business_type", rowData.businessType || "");
      setValue("financing_type", rowData.financingType || "");

      setValue("total", rowData.totalAmount || "");
      setValue("paid", rowData.paidAmount || "");
      setValue("ci_value", rowData.valuationCI || "");
      setValue("ch_value", rowData.valuationCH || "");
      setValue("ci_balance", rowData.balanceCH || "");
      setValue("ch_balance", rowData.balanceCI || "");
      setValue("additional", rowData.additionalCosts || "");
      setValue("work_balance", rowData.worksBalance || "");
      setValue("discount", rowData.discount || "");
      setValue("deed_value", rowData.deedValue || "");
      setValue("expert", rowData.Perito || "");
      setValue("lawyer", rowData.Lawyer || "");
      const fetchData = async () => {
        if (rowData?.Constructor) {
          try {
            const data = await getContactConstructor(rowData?.Constructor?.id);
            setContacts(data || []);
            setSelectedContact(rowData?.ConstructorContact);
            setValue("contact_constructor", rowData?.ConstructorContact || "");
          } catch (error) {

          }
        }

        if (rowData?.Bank) {
          try {
            const fetchedContacts = await getContactBanks(rowData?.Bank?.id);
            setContactsBank(fetchedContacts || []);
            setSelectedContactBank(rowData.BanksContact);
            setValue("contact_bank", rowData.BanksContact || "");
            setValue("entity", rowData.Bank || "");
          } catch (error) {

          }
        }
        if (rowData?.Notary) {
          try {
            const fetchedContacts = await getContactBanks(rowData?.Notary?.id);
            setContactsNotaries(fetchedContacts || []);
            setSelectedContactNotaries(rowData.NotariesContact);
            setValue("contact_notarie", rowData.NotariesContact || "");
            setValue("Notaries", rowData.Notary || "");
          } catch (error) {

          }
        }
      };

      fetchData()
    }

  }, []);
  const getData = async (url, setter) => {
    setLoading(true);
    loadApi(url, true, "get")
      .then((response) => {
        setter(response.data || []);
      })
      .catch((error) => {
        showSnack(error.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleConstructorChange = async (selectedConstructor) => {
    if (selectedConstructor) {
      try {
        const constructorId = selectedConstructor.id;
        const data = await getContactConstructor(constructorId);
        setContacts(data || []); // Guardar contactos
        setValue("contact_constructor", data || ""); // Actualizar el valor en el formulario
      } catch (error) {
      }
    } else {
      setContacts([]); // Limpiar contactos si no hay constructor
    }
  };
  const handleNext = async () => {
    const isStepValid = await trigger();
    if (isStepValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      showSnack("Por favor rellene todos los campos y con datos válidos antes de continuar", "error");
    }
  };

  const handleBankChange = async (selectedBank) => {
    if (selectedBank) {
      const fetchedContacts = await getContactBanks(selectedBank.id);
      setContactsBank(fetchedContacts);
    } else {
      setContactsBank([]);
    }
  };

  const handleNotariesChange = async (selectedNotarie) => {
    if (selectedNotarie) {
      const fetchedContacts = await getContactNotaries(selectedNotarie.id);
      setContactsNotaries(fetchedContacts);
    } else {
      setContactsNotaries([]);
    }
  };


  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  const handleCreate = async (formData) => {

    const dataToSend = {
      nomenclature: formData.nomenclature,
      prorata: formData.prorrata || null,
      registrationNumber: formData.matricula,
      tower: formData.tower || null,
      parking1: formData.parq1 || null,
      registrationParking1: formData.matricula_p1 || null,
      parking2: formData.parq2 || null,
      registrationParking2: formData.matricula_p2 || null,
      utility1: formData.util1 || null,
      registrationUtility1: formData.matricula_u1 || null,
      utility2: formData.util2 || null,
      registrationUtility2: formData.matricula_u2 || null,
      status: formData.status?.id || null,
      businessType: formData.business_type?.id || null,
      financingType: formData.financing_type?.id || null,
      bank_id: formData.entity?.id || null,
      totalAmount: formData.total || null,
      paidAmount: formData.paid || null,
      valuationCI: formData.ci_value || null,
      valuationCH: formData.ch_value || null,
      balanceCI: formData.ci_balance || null,
      balanceCH: formData.ch_balance || null,
      additionalCosts: formData.additional || null,
      worksBalance: formData.work_balance || null,
      discount: formData.discount || null,
      deedValue: formData.deed_value || null,
      third_id: formData.cliente?.id || null,
      perito_id: formData.expert?.id || null,
      lawyer_id: formData.lawyer?.id || null,
      project_id: formData.project?.id,
      constructor_id: formData.constructor_code?.id || null,
      constructor_contact_id: formData?.contact_constructor?.id || null,
      bank_contact_id: formData?.contact_bank?.id || null,
      notary_id: formData?.Notaries?.id || null,
      notary_contact_id: formData?.contact_notarie?.id || null,
    };


    setLoading(true);
    loadApi(`writing/update_writing/${rowData.id}`, true, "put", dataToSend)

      .then((response) => {
        setTimeout(updateList, 0);
        setTimeout(handleClose, 0);
        showSnack(response.data.message, "success");
      })
      .catch((error) => {
        showSnack(error.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });

  };

  return (
    <>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          height: "100%",
          backgroundColor: "#FFFF",
          overflow: "auto",
        }}
      >
        <Box sx={{ padding: "1em" }}>
          <Stepper activeStep={activeStep} sx={{ mb: 4, mt: 4, overflowX: "auto" }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <form>
            {activeStep === 0 && (
              <Stack spacing={2}>
                <Controller
                  name="project"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      disableClearable
                      {...field}
                      options={data}
                      getOptionLabel={(option) => option.project_name || ""}
                      value={rowData ? rowData.Project : null}
                      isOptionEqualToValue={(option, value) => option.project_name === value.project_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Proyectos*"
                          variant="outlined"
                          error={!!errors.project}
                          helperText={errors.project ? errors.project.message : ""}
                        />
                      )}
                      onChange={(_, data) => field.onChange(data)}
                    />
                  )}
                />



                <ContactsStepper
                  control={control}
                  constructors={constructors}
                  contacts={contacts}
                  errors={errors}
                  handleConstructorChange={handleConstructorChange}
                  selectedContact={selectedContact}
                  setSelectedContact={setSelectedContact}
                  constructorName="constructor_code" // Nombre del campo del constructor
                  constructorLabel="Código del Constructor" // Etiqueta del campo del constructor
                  contactName="contact_constructor" // Nombre del campo del contacto
                  contactLabel="Selecciona un contacto para el constructor" // Etiqueta del campo del contacto
                />


                <Controller
                  name="nomenclature"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Nomenclatura*"
                      variant="outlined"
                      error={!!errors.nomenclature}
                      helperText={errors.nomenclature ? errors.nomenclature.message : ""}
                    />
                  )}
                />
                <Controller
                  name="prorrata"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Prorrata"
                      variant="outlined"
                      error={!!errors.prorrata}
                      helperText={errors.prorrata ? errors.prorrata.message : ""}
                    />
                  )}
                />
                <Controller
                  name="matricula"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Matricula"
                      variant="outlined"
                      error={!!errors.matricula}
                      helperText={errors.matricula ? errors.matricula.message : ""}
                    />
                  )}
                />
                <Controller
                  name="tower"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="tower"
                      variant="outlined"
                      error={!!errors.tower}
                      helperText={errors.tower ? errors.tower.message : ""}
                    />
                  )}
                />
              </Stack>
            )}
            {activeStep === 1 && (
              <Stack spacing={2}>
                <Controller
                  name="parq1"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Parqueo 1"
                      variant="outlined"
                      error={!!errors.parq1}
                      helperText={errors.parq1 ? errors.parq1.message : ""}
                    />
                  )}
                />
                <Controller
                  name="matricula_p1"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Matricula P1"
                      variant="outlined"
                      error={!!errors.matricula_p1}
                      helperText={errors.matricula_p1 ? errors.matricula_p1.message : ""}
                    />
                  )}
                />
                <Controller
                  name="parq2"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Parqueo 2"
                      variant="outlined"
                      error={!!errors.parq2}
                      helperText={errors.parq2 ? errors.parq2.message : ""}
                    />
                  )}
                />
                <Controller
                  name="matricula_p2"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Matricula P2"
                      variant="outlined"
                      error={!!errors.matricula_p2}
                      helperText={errors.matricula_p2 ? errors.matricula_p2.message : ""}
                    />
                  )}
                />
                <Controller
                  name="util1"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Util 1"
                      variant="outlined"
                      error={!!errors.util1}
                      helperText={errors.util1 ? errors.util1.message : ""}
                    />
                  )}
                />
                <Controller
                  name="matricula_u1"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Matricula U1"
                      variant="outlined"
                      error={!!errors.matricula_u1}
                      helperText={errors.matricula_u1 ? errors.matricula_u1.message : ""}
                    />
                  )}
                />
                <Controller
                  name="util2"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Util 2"
                      variant="outlined"
                      error={!!errors.util2}
                      helperText={errors.util2 ? errors.util2.message : ""}
                    />
                  )}
                />
                <Controller
                  name="matricula_u2"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Matricula U2"
                      variant="outlined"
                      error={!!errors.matricula_u2}
                      helperText={errors.matricula_u2 ? errors.matricula_u2.message : ""}
                    />
                  )}
                />
              </Stack>
            )}
            {activeStep === 2 && (
              <Stack spacing={2}>
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      options={estatus}
                      getOptionLabel={(option) => option.meaning || ""}
                      defaultValue={rowData ? rowData.status : null}
                      isOptionEqualToValue={(option, value) => option.meaning === value.meaning}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Estado "
                          variant="outlined"
                          error={!!errors.status}
                          helperText={errors.status ? errors.status.message : ""}
                        />
                      )}
                      onChange={(_, data) => field.onChange(data)}
                    />
                  )}
                />
                <Controller
                  name="business_type"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      options={TN}
                      getOptionLabel={(option) => option.meaning || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Tipo negocio *"
                          variant="outlined"
                          error={!!errors.allMeanings}
                          helperText={errors.allMeanings ? errors.allMeanings.message : ""}
                        />
                      )}
                      onChange={(_, data) => field.onChange(data)}
                    />
                  )}
                />
              </Stack>
            )}
            {activeStep === 3 && (
              <Stack spacing={2}>

                <Controller
                  name="financing_type"
                  control={control}

                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      options={TF}
                      getOptionLabel={(option) => option.meaning || ""}
                      defaultValue={rowData ? rowData.financingType : null}
                      isOptionEqualToValue={(option, value) => option.meaning === value.meaning}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Tipo financiación "
                          variant="outlined"
                          error={!!errors.TF}
                          helperText={errors.TF ? errors.TF.message : ""}
                        />
                      )}
                      onChange={(_, data) => field.onChange(data)}
                    />
                  )}
                />




                <ContactsStepper
                  control={control}
                  constructors={banks}
                  contacts={contactsBank}
                  errors={errors}
                  handleConstructorChange={handleBankChange}
                  selectedContact={selectedContactBank}
                  setSelectedContact={setSelectedContactBank}
                  constructorName="entity" // Nombre del campo del constructor
                  constructorLabel="Entidad" // Etiqueta del campo del constructor
                  contactName="contact_bank" // Nombre del campo del contacto
                  contactLabel="Selecciona un contacto para el banco" // Etiqueta del campo del contacto
                />

                <Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
                  <Controller
                    name="total"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Total"
                        variant="outlined"
                        type="text"
                        error={!!errors.total}
                        helperText={errors.total ? errors.total.message : ""}
                        value={
                          field.value !== undefined && field.value !== null
                            ? String(field.value).replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                            : ""
                        }
                        onChange={(e) => field.onChange(e.target.value.replace(/\./g, ""))}
                        onKeyDown={handleKeyDown}
                        fullWidth
                      />
                    )}
                  />

                  <Controller
                    name="paid"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Abonado"
                        variant="outlined"
                        type="text"
                        error={!!errors.paid}
                        helperText={errors.paid ? errors.paid.message : ""}
                        value={field.value !== undefined && field.value !== null ? String(field.value).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : ""}
                        onChange={(e) => field.onChange(e.target.value.replace(/\./g, ""))}
                        onKeyDown={handleKeyDown}
                        fullWidth
                      />
                    )}
                  />
                </Stack>

                <Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
                  <Controller
                    name="ci_value"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Valor CI"
                        variant="outlined"
                        type="text"
                        error={!!errors.ci_value}
                        helperText={errors.ci_value ? errors.ci_value.message : ""}
                        value={field.value !== undefined && field.value !== null ? String(field.value).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : ""}
                        onChange={(e) => field.onChange(e.target.value.replace(/\./g, ""))}
                        onKeyDown={handleKeyDown}
                        fullWidth
                      />
                    )}
                  />

                  <Controller
                    name="ch_value"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Valor CH"
                        variant="outlined"
                        type="text"
                        error={!!errors.ch_value}
                        helperText={errors.ch_value ? errors.ch_value.message : ""}
                        value={field.value !== undefined && field.value !== null ? String(field.value).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : ""}
                        onChange={(e) => field.onChange(e.target.value.replace(/\./g, ""))}
                        onKeyDown={handleKeyDown}
                        fullWidth
                      />
                    )}
                  />
                </Stack>

                <Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
                  <Controller
                    name="ci_balance"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Saldo CI"
                        variant="outlined"
                        type="text"
                        error={!!errors.ci_balance}
                        helperText={errors.ci_balance ? errors.ci_balance.message : ""}
                        value={field.value !== undefined && field.value !== null ? String(field.value).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : ""}
                        onChange={(e) => field.onChange(e.target.value.replace(/\./g, ""))}
                        onKeyDown={handleKeyDown}
                        fullWidth
                      />
                    )}
                  />

                  <Controller
                    name="ch_balance"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Saldo CH"
                        variant="outlined"
                        type="text"
                        error={!!errors.ch_balance}
                        helperText={errors.ch_balance ? errors.ch_balance.message : ""}
                        value={field.value !== undefined && field.value !== null ? String(field.value).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : ""}
                        onChange={(e) => field.onChange(e.target.value.replace(/\./g, ""))}
                        onKeyDown={handleKeyDown}
                        fullWidth
                      />
                    )}
                  />
                </Stack>

                <Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
                  <Controller
                    name="additional"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Adicionales"
                        variant="outlined"
                        type="text"
                        error={!!errors.additional}
                        helperText={errors.additional ? errors.additional.message : ""}
                        value={field.value !== undefined && field.value !== null ? String(field.value).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : ""}
                        onChange={(e) => field.onChange(e.target.value.replace(/\./g, ""))}
                        onKeyDown={handleKeyDown}
                        fullWidth
                      />
                    )}
                  />

                  <Controller
                    name="work_balance"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Saldo obras"
                        variant="outlined"
                        type="text"
                        error={!!errors.work_balance}
                        helperText={errors.work_balance ? errors.work_balance.message : ""}
                        value={field.value !== undefined && field.value !== null ? String(field.value).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : ""}
                        onChange={(e) => field.onChange(e.target.value.replace(/\./g, ""))}
                        onKeyDown={handleKeyDown}
                        fullWidth
                      />
                    )}
                  />
                </Stack>

                <Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
                  <Controller
                    name="discount"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Descuento"
                        variant="outlined"
                        type="text"
                        error={!!errors.discount}
                        helperText={errors.discount ? errors.discount.message : ""}
                        value={field.value !== undefined && field.value !== null ? String(field.value).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : ""}
                        onChange={(e) => field.onChange(e.target.value.replace(/\./g, ""))}
                        onKeyDown={handleKeyDown}
                        fullWidth
                      />
                    )}
                  />

                  <Controller
                    name="deed_value"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Valor escritura"
                        variant="outlined"
                        type="text"
                        error={!!errors.deed_value}
                        helperText={errors.deed_value ? errors.deed_value.message : ""}
                        value={field.value !== undefined && field.value !== null ? String(field.value).replace(/\B(?=(\d{3})+(?!\d))/g, ".") : ""}
                        onChange={(e) => field.onChange(e.target.value.replace(/\./g, ""))}
                        onKeyDown={handleKeyDown}
                        fullWidth
                      />
                    )}
                  />
                </Stack>
              </Stack>
            )}
            {activeStep === 4 && (
              <Stack spacing={2}>
                <Controller
                  name="expert"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete

                      {...field}
                      options={experts}
                      getOptionLabel={(option) => option.name || ""}
                      defaultValue={rowData ? rowData.Perito : null}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Perito "
                          variant="outlined"
                          error={!!errors.expert}
                          helperText={errors.expert ? errors.expert.message : ""}
                        />
                      )}
                      onChange={(_, data) => field.onChange(data)}
                    />
                  )}
                />

                <Controller
                  name="lawyer"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete

                      {...field}
                      options={lawyers}
                      getOptionLabel={(option) => option.name || ""}
                      defaultValue={rowData ? rowData.Lawyer : null}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Abogado "
                          variant="outlined"
                          error={!!errors.lawyer}
                          helperText={errors.lawyer ? errors.lawyer.message : ""}
                        />
                      )}
                      onChange={(_, data) => field.onChange(data)}
                    />
                  )}
                />



                <ContactsStepper
                  control={control}
                  constructors={Notaries}
                  contacts={contactsNotaries}
                  errors={errors}
                  handleConstructorChange={handleNotariesChange}
                  selectedContact={selectedContacNotaries}
                  setSelectedContact={setSelectedContactNotaries}
                  constructorName="Notaries" // Nombre del campo del constructor
                  constructorLabel="Notaria" // Etiqueta del campo del constructor
                  contactName="contact_notarie" // Nombre del campo del contacto
                  contactLabel="Selecciona un contacto para la notaria" // Etiqueta del campo del contacto
                />


              </Stack>
            )}
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="info"
                variant="outlined"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Atrás
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              {activeStep === steps.length - 1 ? (

                <Button onClick={handleSubmit(handleCreate)} >Actualizar</Button>


              ) : (

                <Button onClick={handleNext}>Siguiente</Button>
              )}
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
}