import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import BackButton from "../../components/buttonBack";
import MenuView from "../../components/MenuView";
import SnackAlert from "../../components/SnackAlert";
import useApi from "../../hooks/useApi";
import useSnack from "../../hooks/useSnack";

const schema = yup.object().shape({
  name: yup
    .string()
    .required("Requerido")
    .matches(
      /^[a-zA-ZÀ-ÿ\s0-9./]+$/,
      "Debe contener solo letras, números, puntos o barras"
    ),
});

const textFieldStyle = (enabled) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: enabled ? "black" : "default",
    },
  },
});

export default function Responsible() {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [data, setData] = useState([]);
  const [editableFieldsDisabled, setEditableFieldsDisabled] = useState(true);
  const [creating, setCreating] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { loadApi } = useApi();
  const { showSnack, msgSB, openSB, typeSB } = useSnack();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    loadApi("responsible_persons", true, "get")
      .then((response) => {
        setData(response?.data?.responsible_persons || []);
      })
      .catch((error) => {
        setData([]);
        showSnack(error.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleActivateCreate = () => {
    setEditableFieldsDisabled(false);
    setCreating(true);
    setSelected(null);
  };

  const handleCreate = async (data) => {
    setLoading(true);
    const name = data?.name?.trim();
    loadApi("responsible_persons", true, "post", { name })
      .then((response) => {
        showSnack(response.data.message, "success");
        getData();
        setEditableFieldsDisabled(true);
        setCreating(false);
        clearFields();
      })
      .catch((error) => {
        showSnack(error.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSaveEdit = (data) => {
    setLoading(true);
    const name = data?.name?.trim();
    loadApi(`responsible_persons/${selected.id}`, true, "put", { name })
      .then((response) => {
        showSnack(response.data.message, "success");
        getData();
        setEditableFieldsDisabled(true);
      })
      .catch((error) => {
        showSnack(error.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setLoading(true);
    loadApi(`responsible_persons/${selected.id}`, true, "delete")
      .then((response) => {
        showSnack(response.data.message, "success");
        getData();
        setEditableFieldsDisabled(true);
        setSelected(null);
        clearFields();
        handleCloseDelete();
      })
      .catch((error) => {
        showSnack(error.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpdate = () => {
    if (!selected) {
      showSnack(
        "Por favor seleccione una persona responsable antes de continuar",
        "warning"
      );
      return;
    }
    setEditableFieldsDisabled(false);
  };

  const clearFields = () => {
    setValue("name", "");
  };

  const handleChange = (event, newValue) => {
    setSelected(newValue);
    if (newValue) {
      setValue("name", newValue.name);
      setEditableFieldsDisabled(true);
    } else {
      clearFields();
    }
  };

  const handleOpenDelete = () => setOpenModal(true);
  const handleCloseDelete = () => setOpenModal(false);

  return (
    <MenuView>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <Box
        sx={{
          height: "100%",
          backgroundColor: "#FFFF",
          borderRadius: ".5em",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          overflow: "auto",
        }}
      >
        <Box sx={{ padding: "1em" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1em",
            }}
          >
            {!creating && (
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Stack sx={{ width: "100%" }}>
                  <Stack sx={{ width: "100%" }}>
                    <Typography sx={{ mb: 1 }}>
                      Buscar personas responsables
                    </Typography>
                    <Autocomplete
                      options={data}
                      value={selected}
                      getOptionLabel={(option) => option.name}
                      noOptionsText={"No hay personas responsables"}
                      onChange={handleChange}
                      isOptionEqualToValue={(option, value) =>
                        option.name === value.name
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          variant="outlined"
                          placeholder="Seleccione una opción..."
                        />
                      )}
                    />
                  </Stack>
                </Stack>
              </Box>
            )}
            {creating && (
              <Typography variant="h6" component="h2" align="center">
                Crear Persona Responsable
              </Typography>
            )}
            <Stack justifyContent="center" alignItems="center">
              {!selected && editableFieldsDisabled && !creating && (
                <Stack sx={{ width: "100%", pl: 2 }}>
                  <Alert
                    severity="warning"
                    sx={{ maxWidth: "100%", justifyContent: "center", mt: 2 }}
                  >
                    Seleccione una persona responsable primero o cree uno a
                    continuación.
                  </Alert>
                </Stack>
              )}
              {(selected || !editableFieldsDisabled || creating) && (
                <Stack mt={2}>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        variant="outlined"
                        label="Nombre"
                        disabled={editableFieldsDisabled}
                        error={!!errors.name}
                        required
                        helperText={errors.name?.message}
                        sx={textFieldStyle(!editableFieldsDisabled)}
                      />
                    )}
                  />
                </Stack>
              )}
            </Stack>

            <Stack direction="row" spacing={2} sx={{ justifyContent: "end" }}>
              {selected && editableFieldsDisabled && (
                <Grid container spacing={2} alignItems="center">
                  {/* Botón Atrás */}
                  <Grid item xs={12} md={8}>
                    <BackButton
                      setSelected={setSelected}
                      clearFields={clearFields}
                    />
                  </Grid>

                  {/* Botón Eliminar */}
                  <Grid item xs={12} md={2}>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={handleOpenDelete}
                      sx={{ width: "100%" }}
                      startIcon={<DeleteIcon />}
                    >
                      Eliminar
                    </Button>
                  </Grid>

                  {/* Botón Actualizar */}
                  <Grid item xs={12} md={2}>
                    <Button
                      variant="contained"
                      onClick={handleUpdate}
                      sx={{ width: "100%" }}
                      startIcon={<EditIcon />}
                    >
                      Actualizar
                    </Button>
                  </Grid>
                </Grid>
              )}
              {selected && !editableFieldsDisabled && (
                <Grid container spacing={1}>
                  <Grid item xs={12} md={8} lg={10}>
                    <BackButton
                      setEditableFieldsDisabled={setEditableFieldsDisabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={2}>
                    <Button
                      variant="contained"
                      onClick={handleSubmit(handleSaveEdit)}
                      sx={{ width: "100%" }}
                      startIcon={<SaveIcon />}
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              )}
              {!selected && editableFieldsDisabled && !creating && (
                <Button
                  variant="contained"
                  onClick={handleActivateCreate}
                  sx={{ width: "auto" }}
                  type="submit"
                  startIcon={<AddIcon />}
                >
                  Crear persona responsable
                </Button>
              )}
              {!selected && !editableFieldsDisabled && (
                <Grid container spacing={1}>
                  <Grid item xs={12} md={8} lg={10}>
                    <BackButton
                      setEditableFieldsDisabled={setEditableFieldsDisabled}
                      setCreating={setCreating}
                      clearFields={clearFields}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={2}>
                    <Button
                      variant="contained"
                      onClick={handleSubmit(handleCreate)}
                      sx={{ width: "100%" }}
                      type="submit"
                      startIcon={<DoneIcon />}
                    >
                      Crear ahora
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Stack>
          </Box>
        </Box>
        <Dialog open={openModal}>
          <DialogContent>
            <Stack direction="column" gap={2}>
              <Typography variant="h6">
                ¿Estás seguro de eliminar el elemento?
              </Typography>
              <Typography>Esta acción no se puede deshacer.</Typography>
              <Stack direction="row" gap={2}>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleCloseDelete}
                  sx={{ width: "100%" }}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  onClick={handleDelete}
                  sx={{ width: "100%" }}
                >
                  Confirmar
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>
      </Box>
    </MenuView>
  );
}
