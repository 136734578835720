import { Box, Stack, TextField } from '@mui/material';

const Step4 = ({ row }) => {
    return (
        <Stack spacing={2}>
            <TextField
                label="Cliente*"
                value={row?.Third_party?.name || ""}
                variant="outlined"
                readOnly
                fullWidth
            />
            <TextField
                label="Cliente 2*"
                value={row?.ThirdParty2?.name || ""}
                variant="outlined"
                readOnly
                fullWidth
            />
            <TextField
                label="Cliente 3*"
                value={row?.ThirdParty3?.name || ""}
                variant="outlined"
                readOnly
                fullWidth
            />
            <TextField
                label="Cliente 4*"
                value={row?.ThirdParty4?.name || ""}
                variant="outlined"
                readOnly
                fullWidth
            />
            <TextField
                label="Cliente 5*"
                value={row?.ThirdParty5?.name || ""}
                variant="outlined"
                readOnly
                fullWidth
            />
            <TextField
                label="Cliente 6*"
                value={row?.ThirdParty6?.name || ""}
                variant="outlined"
                readOnly
                fullWidth
            />
            <TextField
                label="Tipo financiación"
                value={row?.financingType?.meaning || ""}
                variant="outlined"
                readOnly
                fullWidth
            />
            <TextField
                label="Entidad"
                value={row?.Bank?.bank_name || ""}
                variant="outlined"
                readOnly
                fullWidth
            />

            {row?.Bank && (
                <TextField
                    label="Contacto del banco"
                    value={row?.BanksContact?.contact_name || ""}
                    variant="outlined"
                    readOnly
                    fullWidth
                />
            )}
            {row?.BanksContact && (
                <Stack direction={{ md: "row", xs: "column" }} spacing={2} sx={{ margin: 0 }}>
                    <Box sx={{ flex: 1 }}>
                        <TextField
                            label="Email"
                            variant="outlined"
                            value={row?.BanksContact?.email}
                            readOnly
                            fullWidth
                        />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <TextField
                            label="Teléfono"
                            variant="outlined"
                            value={row?.BanksContact?.phone}
                            readOnly
                            fullWidth
                        />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <TextField
                            label="Cargo"
                            variant="outlined"
                            value={row?.BanksContact?.position}
                            readOnly
                            fullWidth
                        />
                    </Box>
                </Stack>
            )}

            <TextField
                label="Total"
                value={row?.totalAmount !== undefined ? new Intl.NumberFormat('es-ES').format(row.totalAmount) : ""}
                variant="outlined"
                readOnly
                fullWidth
            />
            <TextField
                label="Abonado"
                value={row?.paidAmount !== undefined ? new Intl.NumberFormat('es-ES').format(row.paidAmount) : ""}
                variant="outlined"
                readOnly
                fullWidth
            />
            <TextField
                label="Valor CI"
                value={row?.valuationCI !== undefined ? new Intl.NumberFormat('es-ES').format(row.valuationCI) : ""}
                variant="outlined"
                readOnly
                fullWidth
            />
            <TextField
                label="Valor CH"
                value={row?.valuationCH !== undefined ? new Intl.NumberFormat('es-ES').format(row.valuationCH) : ""}
                variant="outlined"
                readOnly
                fullWidth
            />
            <TextField
                label="Saldo CI"
                value={row?.balanceCI !== undefined ? new Intl.NumberFormat('es-ES').format(row.balanceCI) : ""}
                variant="outlined"
                readOnly
                fullWidth
            />
            <TextField
                label="Saldo CH"
                value={row?.balanceCH !== undefined ? new Intl.NumberFormat('es-ES').format(row.balanceCH) : ""}
                variant="outlined"
                readOnly
                fullWidth
            />
            <TextField
                label="Adicionales"
                value={row?.additionalCosts !== undefined ? new Intl.NumberFormat('es-ES').format(row.additionalCosts) : ""}
                variant="outlined"
                readOnly
                fullWidth
            />
            <TextField
                label="Saldo obras"
                value={row?.worksBalance !== undefined ? new Intl.NumberFormat('es-ES').format(row.worksBalance) : ""}
                variant="outlined"
                readOnly
                fullWidth
            />
            <TextField
                label="Descuento"
                value={row?.discount !== undefined ? new Intl.NumberFormat('es-ES').format(row.discount) : ""}
                variant="outlined"
                readOnly
                fullWidth
            />
            <TextField
                label="Valor escritura"
                value={row?.deedValue !== undefined ? new Intl.NumberFormat('es-ES').format(row.deedValue) : ""}
                variant="outlined"
                readOnly
                fullWidth
            />

        </Stack>
    );
};

export default Step4;
