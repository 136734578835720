import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import useApi from "../../../../hooks/useApi";

export default function DialogUpdateClients({
  openDialog,
  setOpenDialog,
  client1,
  setClient1,
  client2,
  setClient2,
  client3,
  setClient3,
  client4,
  setClient4,
  client5,
  setClient5,
  client6,
  setClient6,
  loading,
  setLoading,
  id,
  showSnack,
}) {
  const { loadApi } = useApi();
  const [thirdParties, setThirdParties] = useState([]);

  const [newClient1, setNewClient1] = useState(null);
  const [newClient2, setNewClient2] = useState(null);
  const [newClient3, setNewClient3] = useState(null);
  const [newClient4, setNewClient4] = useState(null);
  const [newClient5, setNewClient5] = useState(null);
  const [newClient6, setNewClient6] = useState(null);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewClient1(client1);
    setNewClient2(client2);
    setNewClient3(client3);
    setNewClient4(client4);
    setNewClient5(client5);
    setNewClient6(client6);
  };

  useEffect(() => {
    loadApi("third_parties/all_third_parties", true, "get")
      .then((response) => {
        setThirdParties(response?.data || []);
      })
      .catch((error) => {
        showSnack(error.message, "error");
      });
  }, []);

  useEffect(() => {
    setNewClient1(client1);
    setNewClient2(client2);
    setNewClient3(client3);
    setNewClient4(client4);
    setNewClient5(client5);
    setNewClient6(client6);
  }, [client1, client2, client3, client4, client5, client6]);

  const checkForDuplicateClientIds = (clients) => {
    const clientIds = Object.values(clients).filter((id) => id !== null);
    const uniqueClientIds = new Set(clientIds);
    return uniqueClientIds.size !== clientIds.length;
  };

  const updateClients = () => {
    setLoading(true);

    const newClients = {
      third_id: newClient1 ? newClient1?.id : null,
      third2_id: newClient2 ? newClient2?.id : null,
      third3_id: newClient3 ? newClient3?.id : null,
      third4_id: newClient4 ? newClient4?.id : null,
      third5_id: newClient5 ? newClient5?.id : null,
      third6_id: newClient6 ? newClient6?.id : null,
    };

    if (!newClients.third_id) {
      showSnack("El cliente 1 es obligatorio");
      setLoading(false);
      return;
    }

    const hasDuplicates = checkForDuplicateClientIds(newClients);
    if (hasDuplicates) {
      showSnack("Hay clientes duplicados");
      setLoading(false);
      return;
    }

    loadApi(`writing/update_clients/${id}`, true, "put", newClients)
      .then((response) => {
        showSnack(response?.data?.message, "success");
        setClient1(newClient1);
        setClient2(newClient2);
        setClient3(newClient3);
        setClient4(newClient4);
        setClient5(newClient5);
        setClient6(newClient6);
        handleCloseDialog();
      })
      .catch((error) => {
        showSnack(error?.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog open={openDialog} maxWidth="sm" fullWidth>
      <DialogTitle>Actualizar clientes</DialogTitle>
      <DialogContent>
        <Stack direction="column" p={1} gap={2}>
          <Autocomplete
            fullWidth
            value={newClient1}
            options={thirdParties}
            getOptionLabel={(option) => option?.name || ""}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderOption={(props, option) => (
              <li {...props} key={option?.id}>
                {option?.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Cliente 1*" />
            )}
            onChange={(_, value) => {
              setNewClient1(value);
            }}
          />

          <Autocomplete
            fullWidth
            value={newClient2}
            options={thirdParties}
            getOptionLabel={(option) => option?.name || ""}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderOption={(props, option) => (
              <li {...props} key={option?.id}>
                {option?.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Cliente 2" />
            )}
            onChange={(_, value) => {
              setNewClient2(value);
            }}
          />

          <Autocomplete
            fullWidth
            value={newClient3}
            options={thirdParties}
            getOptionLabel={(option) => option?.name || ""}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderOption={(props, option) => (
              <li {...props} key={option?.id}>
                {option?.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Cliente 3" />
            )}
            onChange={(_, value) => {
              setNewClient3(value);
            }}
          />

          <Autocomplete
            fullWidth
            value={newClient4}
            options={thirdParties}
            getOptionLabel={(option) => option?.name || ""}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderOption={(props, option) => (
              <li {...props} key={option?.id}>
                {option?.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Cliente 4" />
            )}
            onChange={(_, value) => {
              setNewClient4(value);
            }}
          />

          <Autocomplete
            fullWidth
            value={newClient5}
            options={thirdParties}
            getOptionLabel={(option) => option?.name || ""}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderOption={(props, option) => (
              <li {...props} key={option?.id}>
                {option?.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Cliente 5" />
            )}
            onChange={(_, value) => {
              setNewClient5(value);
            }}
          />

          <Autocomplete
            fullWidth
            value={newClient6}
            options={thirdParties}
            getOptionLabel={(option) => option?.name || ""}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderOption={(props, option) => (
              <li {...props} key={option?.id}>
                {option?.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Cliente 6" />
            )}
            onChange={(_, value) => {
              setNewClient6(value);
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button onClick={handleCloseDialog} disabled={loading}>
          Cancelar
        </Button>
        <Button onClick={updateClients} color="success" disabled={loading}>
          Guardar cambios
        </Button>
      </DialogActions>
    </Dialog>
  );
}
