import { Button, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import useApi from "../../../../hooks/useApi";
import ClientComponent from "./clientComponent";
import DialogUpdateClients from "./dialogUpdateClients";

export default function Compradores({ rowData, showSnack }) {
  const { loadApi } = useApi();
  const [loading, setLoading] = useState(false);

  const [openDialogCreate, setOpenDialogCreate] = useState(false);

  const [client1, setClient1] = useState(null);
  const [client2, setClient2] = useState(null);
  const [client3, setClient3] = useState(null);
  const [client4, setClient4] = useState(null);
  const [client5, setClient5] = useState(null);
  const [client6, setClient6] = useState(null);

  const getData = async () => {
    setLoading(true);
    loadApi(`writing/writing_clients/${rowData.id}`, true, "get")
      .then((response) => {
        setClient1(response?.data?.ThirdParty1);
        setClient2(response?.data?.ThirdParty2);
        setClient3(response?.data?.ThirdParty3);
        setClient4(response?.data?.ThirdParty4);
        setClient5(response?.data?.ThirdParty5);
        setClient6(response?.data?.ThirdParty6);
      })
      .catch((error) => {
        showSnack(error.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateClients = (payload, onSuccess) => {
    setLoading(true);
    loadApi(`writing/update_clients/${rowData.id}`, true, "put", payload)
      .then((response) => {
        showSnack(response?.data?.message, "success");
        onSuccess();
      })
      .catch((error) => {
        showSnack(error?.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Stack direction="column" gap={2} mt={2}>
        <Stack justifyContent="end" direction="row">
          <Button onClick={() => setOpenDialogCreate(true)} disabled={loading}>
            Actualizar clientes
          </Button>
        </Stack>
        {client1 ? (
          <ClientComponent
            label="Cliente 1"
            client={client1}
            showDeleteButton={false}
          />
        ) : null}
        {client2 ? (
          <ClientComponent
            label="Cliente 2"
            client={client2}
            handleClickDelete={() => {
              const payload = {
                third_id: client1?.id,
                third2_id: null, // cliente a eliminar
                third3_id: client3 ? client3?.id : null,
                third4_id: client4 ? client4?.id : null,
                third5_id: client5 ? client5?.id : null,
                third6_id: client6 ? client6?.id : null,
              };
              updateClients(payload, () => {
                setClient2(null);
              });
            }}
          />
        ) : null}
        {client3 ? (
          <ClientComponent
            label="Cliente 3"
            client={client3}
            handleClickDelete={() => {
              const payload = {
                third_id: client1?.id,
                third2_id: client2 ? client2?.id : null,
                third3_id: null, // cliente a eliminar
                third4_id: client4 ? client4?.id : null,
                third5_id: client5 ? client5?.id : null,
                third6_id: client6 ? client6?.id : null,
              };
              updateClients(payload, () => {
                setClient3(null);
              });
            }}
          />
        ) : null}
        {client4 ? (
          <ClientComponent
            label="Cliente 4"
            client={client4}
            handleClickDelete={() => {
              const payload = {
                third_id: client1?.id,
                third2_id: client2 ? client2?.id : null,
                third3_id: client3 ? client3?.id : null,
                third4_id: null, // cliente a eliminar
                third5_id: client5 ? client5?.id : null,
                third6_id: client6 ? client6?.id : null,
              };
              updateClients(payload, () => {
                setClient4(null);
              });
            }}
          />
        ) : null}
        {client5 ? (
          <ClientComponent
            label="Cliente 5"
            client={client5}
            handleClickDelete={() => {
              const payload = {
                third_id: client1?.id,
                third2_id: client2 ? client2?.id : null,
                third3_id: client3 ? client3?.id : null,
                third4_id: client4 ? client4?.id : null,
                third5_id: null, // cliente a eliminar
                third6_id: client6 ? client6?.id : null,
              };
              updateClients(payload, () => {
                setClient5(null);
              });
            }}
          />
        ) : null}
        {client6 ? (
          <ClientComponent
            label="Cliente 6"
            client={client6}
            handleClickDelete={() => {
              const payload = {
                third_id: client1?.id,
                third2_id: client2 ? client2?.id : null,
                third3_id: client3 ? client3?.id : null,
                third4_id: client4 ? client4?.id : null,
                third5_id: client5 ? client5?.id : null,
                third6_id: null, // cliente a eliminar
              };
              updateClients(payload, () => {
                setClient6(null);
              });
            }}
          />
        ) : null}
      </Stack>
      <DialogUpdateClients
        openDialog={openDialogCreate}
        setOpenDialog={setOpenDialogCreate}
        client1={client1}
        setClient1={setClient1}
        client2={client2}
        setClient2={setClient2}
        client3={client3}
        setClient3={setClient3}
        client4={client4}
        setClient4={setClient4}
        client5={client5}
        setClient5={setClient5}
        client6={client6}
        setClient6={setClient6}
        loading={loading}
        setLoading={setLoading}
        id={rowData?.id}
        showSnack={showSnack}
      />
    </>
  );
}
