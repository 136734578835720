import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";

export default function ClientComponent({
  label,
  client,
  handleClickDelete,
  showDeleteButton = true,
}) {
  const [openDialogDelete, setOpenDialogDelete] = useState(false);

  const handleCloseDialogDelete = () => {
    setOpenDialogDelete(false);
  };

  return (
    <>
      <Stack direction="column" gap={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>{label}</Typography>
          {showDeleteButton ? (
            <IconButton onClick={() => setOpenDialogDelete(true)} color="error">
              <DeleteIcon />
            </IconButton>
          ) : null}
        </Stack>

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              value={client?.identifier_type || "N/A"}
              label="Tipo de indentificación"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={client?.identifier || "N/A"}
              label="Número de indentificación"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={client?.name || "N/A"}
              label="Nombre"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={client?.email || "N/A"}
              label="Email"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={client?.address || "N/A"}
              label="Dirección"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={client?.city || "N/A"}
              label="Ciudad"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={client?.phone || "N/A"}
              label="Teléfono"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={client?.contact_name || "N/A"}
              label="Nombre de contacto"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={client?.contact_email || "N/A"}
              label="Email de contacto"
              fullWidth
              disabled
            />
          </Grid>
        </Grid>
      </Stack>
      <Dialog open={openDialogDelete}>
        <DialogTitle>Eliminar cliente</DialogTitle>
        <DialogContent>
          ¿Estás seguro de que deseas eliminar este cliente?
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button onClick={handleCloseDialogDelete}>Cancelar</Button>
          <Button onClick={handleClickDelete} color="error">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
